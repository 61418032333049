<template>
<span>
    <template>
        <v-layout row justify-end>
            <v-dialog v-model="dialog_foto" persistent max-width="600px">
                <v-card>
                    <v-card-text>
                        <v-container grid-list-md>
                            <v-layout wrap>
                                <v-flex xs12>
                                    <img v-if="prod_co_img" :src="`${base_url}upload/product_color/${prod_co_img}?${uuid}`" class="foto-ampliada" />
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue-grey darken-1" @click="dialog_foto = false">Fechar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </template>

    <v-container fluid>
        <v-row>
            <v-col cols="6">
                <v-flex xs10>
                    <h3 class="blue-grey--text lighten-1 pb-3">Cores Disponíveis</h3>
                </v-flex>
                <list-product-colors v-on:atualizar="atualizaCoresAtribuidas" :product="product"></list-product-colors>
            </v-col>

            <v-col cols="6">
                <v-flex xs10>
                    <h3 class="blue-grey--text lighten-1 pb-3">Cores Atribuídas ao Produto: {{ product.prod_nome }}</h3>
                </v-flex>

                <v-card-title class="grey darken-4">
                    <v-text-field v-model="search" append-icon="search" label="Localizar" single-line hide-details></v-text-field>
                </v-card-title>

                <v-data-table :headers="headers" :items="loadProductWithColors" :search="search" :rowsPerPageItems="rowsPerPageItems">

                    <template v-slot:[`item.prod_co_img`]="{ item }">
                        <td width="15%" v-if="item.prod_co_img"><img :src="`${base_url}upload/product_color/${item.prod_co_img}?${uuid}`" class="avatar" @click.prevent="ampliarFoto(item.prod_co_img)"></td>
                        <td width="15%" v-if="!item.prod_co_img"><img :src="`${base_url}upload/logo.png`" class="avatar"></td>
                    </template>

                    <template v-slot:[`item.acoes`]="{ item }">

                        <v-tooltip top color="red white--text">
                            <template v-slot:activator="{ on }">
                                <v-btn icon color="red lighten-1" @click.prevent="removeColor(item)" v-on="on">
                                    <v-icon dark class="mdi mdi-delete"></v-icon>
                                </v-btn>
                            </template>
                            <span>Excluir Cor</span>
                        </v-tooltip>

                    </template>

                    <template v-slot:no-results>
                        <v-alert :value="true" color="error" icon="warning">Voce procurou por "{{ search }}" nenhum item localizado.</v-alert>
                    </template>

                </v-data-table>
            </v-col>
        </v-row>
    </v-container>

</span>
</template>

<script>
import {
    uuid
} from "vue-uuid";
import {
    URL_BASE
} from '../../../../config/configs'
import ListProductColorsComponent from '../../partials/ListProductColorsComponent.vue';

export default {
    name: "GaleriaColorProductComponent",
    created() {
        this.$store.dispatch("loadProductWithColors", this.product.prod_id);
    },
    computed: {
        loadProductWithColors() {
            if (this.$store.state.products.colors.data !== null) {
                return this.$store.state.products.colors.cores;
            }
        }
    },
    props: {
        product: {
            require: false,
            type: Object | Array,
            default: () => {
                return {
                    prod_id: '',
                    prod_nome: '',
                }
            },
        },
        require: true,
    },

    data() {
        return {
            productColor: {
                require: false,
                type: Object | Array,
                default: () => {
                    return {
                        prod_co_id: '',
                        prod_co_nome: '',
                        prod_co_hexa: '',
                        prod_co_uint: '',
                        prod_co_cod: '',
                        prod_co_tipo: '',
                        prod_co_status: '',
                    }
                }
            },
            dialog_foto: "",
            prod_co_img: null,
            search: "",
            rowsPerPageItems: [
                10,
                20,
                30,
                {
                    text: "$vuetify.dataIterator.rowsPerPageAll",
                    "10": -1
                }
            ],
            headers: [{
                    text: "Cor",
                    align: "left",
                    sortable: false,
                    value: "prod_co_img"
                },
                {
                    text: "Nome",
                    value: "prod_co_nome",
                    sortable: false
                },
                {
                    text: "Tipo",
                    value: "prod_co_tipo",
                    sortable: false
                },
                {
                    text: "Ações",
                    align: "center",
                    sortable: false,
                    value: "acoes"
                }
            ],
            base_url: URL_BASE,
            uuid: uuid.v1()
        }
    },

    methods: {
        ampliarFoto(foto) {
            this.prod_co_img = foto;
            this.dialog_foto = true;
        },
        atualizaCoresAtribuidas() {
            this.$store.dispatch("loadProductWithColors", this.product.prod_id);
        },
        removeColor(item, index) {
            this.$swal({
                title: 'Você tem certeza?',
                text: "Você não poderá reverter isso",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#D32F2F',
                cancelButtonColor: '#1976D2',
                confirmButtonText: 'Sim, Exclua!'
            }).then((result) => {
                if (result.value) {
                    this.$store.dispatch('removeColorAtribuida', {product_id: this.product.prod_id, product_color_id: item.prod_co_id})
                        .then((response) => {
                            if (response.success) {
                                this.$store.state.products.colors.cores.splice(index, 1);
                            } else {
                                this.$swal({
                                    position: "center",
                                    icon: "error",
                                    title: 'Opssss!',
                                    text: 'Erro ao excluir dados',
                                    showConfirmButton: true,
                                    timer: 6000
                                });
                            }
                        })
                        .catch(error => {
                            console.log(error)
                            this.$swal({
                                position: "center",
                                icon: "error",
                                title: 'Opssss!',
                                text: 'Algo errado aconteceu!',
                                showConfirmButton: true,
                                timer: 6000
                            });
                        })
                }
            })

        }
    },
    components: {
        ListProductColors: ListProductColorsComponent,
    }
};
</script>

<style scoped>
.avatar {
    height: 40px;
    margin-top: 0.4rem;
}

.foto-ampliada {
    max-width: 100%;
}

.theme--dark.v-sheet {
    background-color: #303030;
    border-color: #303030;
    color: #fff;
    box-shadow: none;
}

.btn-upload,
.btn-salvar {
    margin: 0;
}

/*Also*/
.fileinput-button input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    -ms-filter: 'alpha(opacity=0)';
    filter: 'alpha(opacity=0)';
    font-size: 200px;
    direction: ltr;
    cursor: pointer;
}

.img-preview {
    max-width: 100%;
}

.is-capa {
    color: darkorange !important;
}
</style>
