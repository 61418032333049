<template>
<span>
    <template>
        <v-layout row justify-end>
            <v-dialog v-model="dialog_foto" persistent max-width="600px">
                <v-card>
                    <v-card-text>
                        <v-container grid-list-md>
                            <v-layout wrap>
                                <v-flex xs12>
                                    <img v-if="prod_co_img" :src="`${base_url}upload/product_color/${prod_co_img}?${uuid}`" class="foto-ampliada" />
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue-grey darken-1" @click="dialog_foto = false">Fechar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </template>

    <v-card>
        <v-card-title>
            <v-text-field v-model="search" append-icon="search" label="Localizar" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="loadProductColors" :search="search" :rowsPerPageItems="rowsPerPageItems">

            <template v-slot:[`item.prod_co_img`]="{ item }">
                <td width="15%" v-if="item.prod_co_img"><img :src="`${base_url}upload/product_color/${item.prod_co_img}?${uuid}`" class="avatar" @click.prevent="ampliarFoto(item.prod_co_img)"></td>
                <td width="15%" v-if="!item.prod_co_img"><img :src="`${base_url}upload/logo.png`" class="avatar"></td>
            </template>

            <template v-slot:[`item.acoes`]="{ item }">

                <v-tooltip top color="blue white--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="blue lighten-1" @click.prevent="adicionarCor(item)" v-on="on">
                            <v-icon dark>mdi-table-arrow-right</v-icon>
                        </v-btn>
                    </template>
                    <span>Adicionar Cor</span>
                </v-tooltip>

            </template> <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning">Voce procurou por "{{ search }}" nenhum item localizado.</v-alert>
            </template>
        </v-data-table>
    </v-card>
</span>
</template>

<script>
import {
    uuid
} from "vue-uuid";
import {
    URL_BASE
} from "../../../config/configs";

export default {
    name: "ListProductColorsComponent",
    created() {
        this.$store.dispatch("loadProductColors", );
    },
    computed: {
        loadProductColors() {
            if (this.$store.state.productcolors.items.data !== null) {
                return this.$store.state.productcolors.items.data;
            }
        }
    },
    props: {
        prod_co_id: {
            require: true
        },
        product: {
            require: true
        }
    },
    data() {
        return {
            dialog_foto: "",
            prod_co_img: null,
            search: "",
            rowsPerPageItems: [
                10,
                20,
                30,
                {
                    text: "$vuetify.dataIterator.rowsPerPageAll",
                    "10": -1
                }
            ],
            headers: [{
                    text: "Cor",
                    align: "left",
                    sortable: false,
                    value: "prod_co_img"
                },
                {
                    text: "Nome",
                    value: "prod_co_nome",
                    sortable: false
                },
                {
                    text: "Tipo",
                    value: "prod_co_tipo",
                    sortable: false
                },
                {
                    text: "Ações",
                    align: "center",
                    sortable: false,
                    value: "acoes"
                }
            ],
            base_url: URL_BASE,
            uuid: uuid.v1()
        };
    },
    methods: {
        adicionarCor(item) {
            this.$store.dispatch('atribuiColorByProduct', {product_id: this.product.prod_id, product_color_id: item.prod_co_id})
                .then((response) => {
                    if (response.success) {
                        this.$emit('atualizar');
                    }
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: 'Erro',
                        text: 'Algo deu errado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                    console.log(error)
                })

        },
        ampliarFoto(foto) {
            this.prod_co_img = foto;
            this.dialog_foto = true;
        }
    }
};
</script>

<style scoped>
.avatar {
    height: 40px;
    margin-top: 0.4rem;
}

.foto-ampliada {
    max-width: 100%;
}
</style>
